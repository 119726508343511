<template>
  <div>
    <m-layout-bar>
      <div class="noticeWrap en" :style="{background:'url('+bgImgUrl+')',backgroundSize:'100% 100%'}">
        <div class="contentWrap_notice">
          <!-- <img class="bg" :src="require('../assets/image/notice/bg.png')" alt /> -->
          <div class="content clearfloat">
            <div class="layout_left">
              <div class="tabWrap">
                <div class="asideWrapTop">
                  <span class="title">Tourism services</span>
                  <ul class="tab">
                    <li
                      class="item travelingInfo"
                      data-index="0"
                      @click="clickHandle"
                      :class="{'active':activeIndex==='0'}"
                    >
                      <router-link to="/service_en/traffic_en">
                        <img
                          class="icon"
                          :src="require('../assets/image/notice/travelingInfo.png')"
                          alt
                        />
                        <span>
                          Traffic
                          <br />information
                        </span>
                      </router-link>
                    </li>
                    <li
                      class="item guide"
                      data-index="1"
                      @click="clickHandle"
                      :class="{'active':activeIndex==='1'}"
                    >
                      <router-link to="/service_en/notice_en">
                        <img
                          class="icon"
                          :src="require('../assets/image/notice/playNotice.png')"
                          alt
                        />
                        <span>
                          Travel
                          <br />instructions
                        </span>
                      </router-link>
                    </li>
                    <li
                      class="item playNotice"
                      data-index="2"
                      @click="clickHandle"
                      :class="{'active':activeIndex==='2'}"
                    >
                      <router-link to="/service_en/guide_en">
                        <img :src="require('../assets/image/notice/guide.png')" alt />
                        <span>Tour guice</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <div class="asideWrapBottom">
                  <span class="title">Business Hours</span>
                  <span class="time">8:00-17:30</span>
                  <img class="iGap" :src="require('../assets/image/notice/gap.png')" alt />
                  <div class="code2">
                    <!-- <img class="iLt" :src="require('../assets/image/notice/s_lt.png')" alt />
                    <img class="iRt" :src="require('../assets/image/notice/s_rt.png')" alt />
                    <img class="iLb" :src="require('../assets/image/notice/s_lb.png')" alt />
                    <img class="iRb" :src="require('../assets/image/notice/s_rb.png')" alt />-->
                    <div class="iCodeWrap">
                      <img class="iCode" :src="require('../assets/image/notice/code.png')" alt />
                    </div>
                    <div class="bottomWrap">
                      <img class="phone" :src="require('../assets/image/notice/phone.png')" alt />
                      <span>Customer service</span>
                      <div class="phoneNo">400-661-5666</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="layout_right">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar_en";
export default {
  components: {
    [LayoutBar.name]: LayoutBar
  },
  data() {
    return {
      activeIndex: "",
      bgImgUrl: require("../assets/image/notice/bg.png")
    };
  },
  created() {
    // console.log("in created");
    // console.log(this.$route)
    let index = this.$route.meta.index;
    this.activeIndex = index;
    // console.log("index:",index);
  },
  mounted() {
    let path = this.$route.path;
    if (path.indexOf("traffic_en") > 0) {
      this.activeIndex = "0";
    } else if (path.indexOf("notice_en") > 0) {
      this.activeIndex = "1";
    } else if (path.indexOf("guide_en") > 0) {
      this.activeIndex = "2";
    }
  },
  methods: {
    clickHandle(e) {
      this.activeIndex = e.currentTarget.getAttributeNode("data-index").value;
      // console.log(this.activeIndex);
      if (!this.activeIndex) {
        this.activeIndex = "";
      }
      // if (
      //   !event &&
      //   !event.target &&
      //   !event.target.getAttributeNode("data-index")
      // ) {
      //   return;
      // }
      // console.log(
      //   "data-index",
      //   event.target,
      //   event.target.getAttributeNode("data-index")
      // );
      // let index = event.target.getAttributeNode("data-index").value;
      // //   if (!index) {
      // //     this.cur = -1;
      // //   }else{
      // this.cur = parseInt(index);
      //   }
    }
  },
  watch: {
    $route(to, from) {
      // console.log('from.path:',from.path);//从哪来
      // console.log('to.path:',to.path);//到哪去
      // console.log(this.$route.meta.index)
      let index = this.$route.meta.index;
      if (!index) return;
      this.activeIndex = index;
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.noticeWrap {
  background: $main-bgYellow;
  border: 1px solid transparent;
  .contentWrap_notice {
    min-height: 100%;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      // width: 100%;
      // height: 100%;
      min-height: 100%;
      //   height: 1222px;
      opacity: 0.7;
      z-index: -1;
    }
    .content {
      position: relative;
      // position: absolute;
      // top: 160px;
      // left: 0px;
      // right: 0;
      margin: 50px auto 50px auto;
      padding-top: 50px;
      width: 1400px;
      // height: 100%;
      //   height: 1100px;
      min-height: 100%;
      // background: #ccc;
      .layout_left {
        // float: left;
        display: inline-block;
        width: 318px;
        height: 100%;
        color: $main-white;
        margin-right: 19px;
        .asideWrapTop {
          border-radius: 10px;
          background: $main-blueMenu;
          padding-bottom: 60px;
          .title {
            font-size: 30px;
            font-weight: bolder;
            padding-top: 34px;
            padding-left: 21px;
            padding-bottom: 38px;
            display: block;
            background: $main-blueDark;
            border-radius: 10px;
            // border: 1px solid transparent;
          }
          .item {
            font-size: 26px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            padding: 0px 23px 0px 16px;
            a:-webkit-any-link {
              color: $main-white;
              display: block;
              width: 281px;
              height: 106px;
              padding-top: 34px;
              padding-left: 12px;
              // padding: 30px 0px 30px 30px;
              box-sizing: border-box;
              .icon {
                vertical-align: super;
              }
              > span {
                display: inline-block;
                width: 184px;
                height: 100px;
              }
              &:hover {
                border-bottom: 5px solid rgba(255, 255, 255, 0.5);
              }
              &:focus,
              &:active {
                // background: $main-blue;
                border-bottom: 5px solid $main-white;
              }
            }
            img {
              vertical-align: text-bottom;
              margin-right: 29px;
            }
            &.active {
              // background: $main-blueDark;
              > a {
                border-bottom: 5px solid $main-white;
              }
            }
            // &.question:hover {
            //   border-bottom-left-radius: 10px;
            //   border-bottom-right-radius: 10px;
            // }
          }
        }
        .asideWrapBottom {
          position: relative;
          // height: 499px;
          margin-top: 23px;
          margin-bottom: 40px;
          padding-top: 37px;
          padding-bottom: 40px;
          border-radius: 10px;
          background: $main-blueMenu;
          .title {
            display: block;
            font-size: 30px;
            font-weight: bold;
            padding-left: 27px;
            padding-bottom: 25px;

            // border: 1px solid transparent;
          }
          .time {
            display: block;
            font-size: 24px;
            padding-left: 27px;
          }
          .iGap {
            position: absolute;
            width: 100%;
            top: 148px;
          }
          .iLt,
          .iLb {
            position: absolute;
            left: 50px;
          }
          .iRt,
          .iRb {
            position: absolute;
            right: 49px;
          }
          .iLt,
          .iRt {
            top: 196px;
          }
          .iLb,
          .iRb {
            top: 332px;
          }
          .iCodeWrap {
            margin-top: 89px;
            text-align: center;
            .iCode {
              // position: absolute;
              // top: 205px;
              // left: 60px;
            }
          }

          .bottomWrap {
            margin-top: 54px;
            padding-left: 28px;
            font-size: 18px;
            img {
              margin-right: 14px;
              vertical-align: baseline;
            }
            .phoneNo {
              margin-top: 19px;
              font-size: 24px;
              font-weight: bold;
            }
          }
        }
      }
      .layout_right {
        // float: right;
        display: inline-block;
        width: 1061px;
        min-height: 583px;
        vertical-align: top;
        background: $main-white;
        border-radius: 10px;
        .tabDetailWrap {
          min-height: 500px;
          padding-left: 40px;
          padding-right: 40px;
          padding-top: 50px;
        }
        border: 1px solid transparent;

        // 常见问题
        .tabDetailWrap.question {
          .logoRight {
            .title {
              color: $main-red;
            }
          }
          ul {
            margin-top: 30px;
            .groupQ {
              margin-top: 60px;
              margin-bottom: 54px;
            }
            .info {
              display: inline-block;
              position: relative;
              width: calc(100% - 130px);
              padding: 35px 0px 35px 47px;

              border-radius: 20px;
              color: $main-white;

              &.qInfo {
                background: $main-redQuestion;
                margin-left: 25px;
                font-size: 33px;
                img {
                  position: absolute;
                  left: -20px;
                }
              }
              &.aInfo {
                background: $main-yellow;
                margin-right: 25px;
                font-size: 22px;
                img {
                  position: absolute;
                  right: -20px;
                }
              }
            }
          }
        }
        .tabDetail {
          // padding-left: 40px;
          // padding-right: 40px;
          // padding-top: 50px;
          .tabLogo {
            vertical-align: super;
            margin-right: 47px;
            width: 104px;
            height: 100px;
          }
          .logoRight {
            display: inline-block;
            .title {
              font-size: 45px;
              font-weight: bold;
              color: $main-blueDark;
              margin-bottom: 20px;
            }
            .subtitle {
              margin-top: 20px;
            }
          }
          .logoInfo {
            margin-top: 65px;
            padding: 30px 50px 30px 15px;
            background: $main-blueMenu;
            border-radius: 10px;
            .welcomeInfo {
              img {
                vertical-align: top;
              }
              span {
                // float: left;
                display: inline-block;
                width: calc(100% - 100px);
                margin-right: 2px;
                margin-left: 20px;
                color: $main-white;
                font-size: 15px;
              }
            }
          }
          .detailWrap {
            margin-top: 30px;
            // ul {
            li {
              padding: 20px 0px;
              .num {
                display: inline-block;
                width: 34px;
                height: 34px;
                line-height: 34px;
                // margin-top: 10px;
                border-radius: 50%;
                background: $main-red;
                vertical-align: top;
                text-align: center;
                color: $main-white;
              }
              .detail {
                display: inline-block;
                width: calc(100% - 100px);
                margin-left: 27px;
                font-size: 13px;
                color: $font-grayDarkD;
                line-height: 1.7;
              }
            }
            // }
          }
        }
      }
    }
  }
}
</style>
